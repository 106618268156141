<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<div class="row my-auto mx-auto justify-content-center">
			<div class="col-xl-7">
				<div class="card">
					<div class="card-body">
						<div>
							<h4 class="header-title mb-2">
								Welcome {{ $store.state.authModule.currentUser.name }}
							</h4>
							<h4 class="sub-header mb-0">{{ wizardInstructions }}</h4>
						</div>

						<form-wizard @on-change="handleTabChange" color="#212121" ref="wizard">
							<template #step>
								<span></span>
							</template>

							<tab-content icon="mdi mdi-account-circle">
								<div class="row mt-3">
									<div class="col-6">
										<div class="form-group row my-3 mr-2">
											<label for="location">Please Select Your Branch</label>
											<select
												name="location"
												id="location"
												class="form-control"
												v-model="selectedBranch"
											>
												<option
													v-for="(branch, index) in branches"
													:key="index"
													:value="branch._id"
												>
													{{ branch.name }}
												</option>
											</select>
										</div>
									</div>
									<!-- end col -->

									<div class="col-6">
										<div class="form-group row my-3 mr-2">
											<div class="form-group">
												<label for="startDate">Date of reading</label>
												<date-picker
													value-type="YYYY-MM-DD"
													format="YYYY-MM-DD"
													v-model="transactionDate"
													name="transactionDate"
													id="transactionDate"
													type="date"
													lang="en"
													confirm
													placeholder="Select A Date"
												/>
											</div>
										</div>
									</div>
									<!-- end col -->
								</div>
								<!-- end row -->
							</tab-content>

							<tab-content icon="mdi mdi-account-circle">
								<div class="row mt-3">
									<div class="col-12">
										<div class="form-group row my-3 mr-2">
											<label for="jackpotPaid"
												>Did the jackpot amount of
												{{ loadedBranch ? formatAsMoney(loadedBranch.jackpotAmount) : "" }}
												get paid out?</label
											>
											<select
												name="jackpotPaid"
												id="jackpotPaid"
												class="form-control"
												v-model="isJackpot"
											>
												<option :value="true">Yes</option>
												<option :value="false">No</option>
											</select>
										</div>
									</div>
									<!-- end col -->
								</div>
								<!-- end row -->
							</tab-content>

							<tab-content icon="mdi mdi-face-profile">
								<div class="row mt-3">
									<div
										class="col-12 pr-4"
										id="scrollspy-nested"
										style="position: relative; height: 420px; overflow-y: auto"
										v-if="gameboxTransactions.length > 0"
									>
										<template v-for="(gameboxTransaction, index) in gameboxTransactions">
											<div :key="index" class="mb-4">
												<div class="h6 text-muted">
													{{ gameboxTransaction.name }}
												</div>
												<div class="h6 text-muted">Serial Number: {{ gameboxTransaction.sn }}</div>
												<div class="h6 text-muted">Game: {{ gameboxTransaction.gameName }}</div>
												<div v-if="gameboxTransaction.locationOrder" class="h6 text-muted">
													Position Of Machine On Site: {{ gameboxTransaction.locationOrder }}
												</div>

												<div class="form-group row mb-3">
													<div class="col-md-6 col-form-label">
														<label for="name">Meter Reading In</label>
														<input
															type="number"
															name="name"
															class="form-control"
															v-model.number="gameboxTransaction.readingIn"
														/>
													</div>
													<div class="col-md-6 col-form-label">
														<label for="name">Meter Reading Out</label>
														<input
															type="number"
															name="name"
															class="form-control"
															v-model.number="gameboxTransaction.readingOut"
														/>
													</div>
												</div>
												<hr />
											</div>
										</template>
									</div>
									<!-- end col -->

									<div class="col-12 pr-4 text-center" id="scrollspy-nested" v-else>
										<template>
											<div class="mb-5">
												<div class="my-3">
													<i class="mdi mdi-48px mdi-server-off"></i>
												</div>
												<p class="h4 my-2">Sorry No Machines To Display</p>
												<p class="my-2">There is no machine in the location selected.</p>
												<p class="my-2">Please go back and try entering a different location</p>
											</div>
										</template>
									</div>
									<!-- end col -->
								</div>
								<!-- end row -->
							</tab-content>

							<tab-content>
								<div v-if="transactionSummary" class="row my-3 justify-content-center mb-5">
									<div class="col-10 align-self-center">
										<div class="text-center mb-4">
											<h2 class="mt-0">
												<i class="mdi mdi-check-all"></i>
											</h2>
											<h3 class="mt-0">Thank you !</h3>
											<p class="header-title text-muted">Transaction Summary</p>
										</div>

										<b-row no-gutters>
											<b-col cols="12">
												<b-row class="border">
													<b-col cols="12" sm="6" class="mt-2">
														<span class="font-weight-bold"> Money In : </span>
													</b-col>
													<b-col cols="12" sm="6" class="mt-2 text-right">
														{{
															transactionSummary ? formatAsMoney(transactionSummary.totalIn) : ""
														}}
													</b-col>
													<b-col cols="12" sm="6" class="mt-2">
														<span class="font-weight-bold"> Money Out : </span>
													</b-col>
													<b-col cols="12" sm="6" class="mt-2 text-right">
														{{
															transactionSummary ? formatAsMoney(transactionSummary.totalOut) : ""
														}}
													</b-col>
													<b-col cols="12" sm="6" class="my-2">
														<span class="font-weight-bold"> Gross Profit : </span>
													</b-col>
													<b-col cols="12" sm="6" class="my-2 text-right">
														{{
															transactionSummary
																? formatAsMoney(transactionSummary.grossProfit)
																: ""
														}}
													</b-col>
												</b-row>

												<!--  Commission Calculations-->
												<b-row class="border mt-3">
													<b-col cols="12" class="pa-0 ma-0">
														<span class="font-weight-bold text-danger">Bar Commission:</span>
													</b-col>
													<b-col cols="12" sm="6" class="mt-2">
														<span class="font-weight-bold"> Gross Profit : </span>
													</b-col>
													<b-col cols="12" sm="6" class="mt-2 text-right">
														{{
															transactionSummary
																? formatAsMoney(transactionSummary.grossProfit)
																: ""
														}}
													</b-col>
													<b-col cols="12" sm="6" class="mt-2">
														<span class="font-weight-bold"> Commission Percent : </span>
													</b-col>
													<b-col cols="12" sm="6" class="mt-2 text-right">
														{{
															transactionSummary
																? " x " +
																  findPercentage(
																		transactionSummary.commission,
																		transactionSummary.grossProfit
																  ) +
																  "%"
																: ""
														}}
													</b-col>

													<b-col cols="12" sm="6" class="my-2">
														<span class="font-weight-bold"> Commission : </span>
													</b-col>
													<b-col cols="12" sm="6" class="my-2 text-right">
														{{
															transactionSummary ? formatAsMoney(transactionSummary.commission) : ""
														}}
													</b-col>
												</b-row>

												<!------- Net Profit Calculation ---------->
												<b-row class="border mt-3">
													<b-col cols="12" class="pa-0 ma-0">
														<span class="font-weight-bold text-danger">Company Profit:</span>
													</b-col>
													<b-col cols="12" sm="6" class="mt-2">
														<span class="font-weight-bold"> Gross Profit : </span>
													</b-col>
													<b-col cols="12" sm="6" class="mt-2 text-right">
														{{
															transactionSummary
																? formatAsMoney(transactionSummary.grossProfit)
																: ""
														}}
													</b-col>

													<b-col cols="12" sm="6" class="mt-2">
														<span class="font-weight-bold"> Profit Percent: </span>
													</b-col>
													<b-col cols="12" sm="6" class="mt-2 text-right">
														{{
															transactionSummary
																? " x " +
																  findPercentage(
																		transactionSummary.profits,
																		transactionSummary.grossProfit
																  ) +
																  "%"
																: ""
														}}
													</b-col>

													<b-col cols="12" sm="6" class="my-2">
														<span class="font-weight-bold"> Profit : </span>
													</b-col>
													<b-col cols="12" sm="6" class="my-2 text-right">
														{{
															transactionSummary ? formatAsMoney(transactionSummary.profits) : ""
														}}
													</b-col>
												</b-row>

												<!-------- Float to reinject Calculation----------->
												<b-row class="border mt-3">
													<b-col cols="12" sm="6" class="mt-2">
														<span class="font-weight-bold"> Float currently at location : </span>
													</b-col>
													<b-col cols="12" sm="6" class="mt-2 text-right">
														{{
															transactionSummary
																? formatAsMoney(transactionSummary.amountCurrentlyAtBar)
																: ""
														}}
													</b-col>

													<b-col cols="12" sm="6" class="mt-2">
														<span class="font-weight-bold"> Commission : </span>
													</b-col>
													<b-col cols="12" sm="6" class="mt-2 text-right">
														{{
															transactionSummary
																? "+" + formatAsMoney(transactionSummary.commission)
																: ""
														}}
													</b-col>

													<b-col cols="12" sm="6" class="my-2">
														<span class="font-weight-bold"> Amount to be left at bar : </span>
													</b-col>
													<b-col cols="12" sm="6" class="my-2 text-right">
														{{
															transactionSummary
																? formatAsMoney(transactionSummary.amountLeftAtBar)
																: ""
														}}
													</b-col>
												</b-row>
											</b-col>
										</b-row>
									</div>
									<!-- end col -->
								</div>
								<!-- end row -->
							</tab-content>

							<template #prev="props">
								<b-button class="ml-1 mb-3" vairant="primary" :style="props.fillButtonStyle">
									Back
								</b-button>
							</template>

							<template #next="props">
								<template v-if="props.activeTabIndex < 2">
									<b-button
										:disabled="loading"
										:style="props.fillButtonStyle"
										class="ml-1 mb-3"
										vairant="primary"
									>
										Next
									</b-button>
								</template>

								<template v-if="props.activeTabIndex == 2">
									<b-button variant="primary" class="ml-1 mb-3" @click="submitMachineForm()">
										Upload Transactions</b-button
									>
								</template>
								<template v-if="props.activeTabIndex < 2">
									<span></span>
								</template>
							</template>

							<template #finish>
								<span></span>
							</template>
						</form-wizard>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
	import Layout from "../../../layouts/vertical";
	import PageHeader from "@/components/Page-header";
	import { FormWizard, TabContent } from "vue-form-wizard";
	import { mapState, mapActions, mapGetters } from "vuex";
	import dayjs from "dayjs";
	import DatePicker from "vue2-datepicker";
	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	});

	/**
	 * Starter component
	 */
	export default {
		page: {
			title: "Record Transaction",
		},
		data() {
			return {
				title: "Form",
				items: [
					{
						text: "Employee Form",
						active: true,
					},
				],
				gameboxTransactions: [],
				operations: [
					{
						name: "Reqular",
						value: "REGULAR",
					},
				],
				transactionSummary: null,
				selectedBranch: "",
				selectedOperation: "REGULAR",
				isJackpot: false,
				transactionDate: dayjs().format("YYYY-MM-DD"),
				wizardInstructions:
					"This form wizard will assist you with entering machine values for your branch. Please select a branch then click the next button",
			};
		},

		async beforeMount() {
			this.gameboxTransactions = [];
			this.getGameboxes();
			this.getBranches();
		},

		components: {
			FormWizard,
			TabContent,
			Layout,
			PageHeader,
			DatePicker,
		},

		computed: {
			...mapGetters("gameboxModule", ["getGameboxByBranch"]),
			...mapState("gameModule", ["games"]),
			...mapState("branchModule", ["branches"]),
			...mapState("gameboxModule", ["gameboxes"]),
			...mapState("authModule", ["currentUser"]),

			loading() {
				if ((!this.selectedBranch, !this.selectedOperation)) return true;
				else return false;
			},
			loadedBranch() {
				return this.branches.find((b) => b._id == this.selectedBranch);
			},
		},

		methods: {
			...mapActions("gameModule", ["getGames"]),
			...mapActions("gameboxModule", ["getGameboxes"]),
			...mapActions("branchModule", ["getBranches"]),
			...mapActions("transactionsModule", [
				"createTransaction",
				"getTransactionSummaryDetails",
				"getLatestIotTransactions",
				"markIotComplete",
			]),

			async loadGameboxes() {
				const gameboxes = this.getGameboxByBranch(this.selectedBranch);
				this.gameboxTransactions = gameboxes.map((gamebox) => {
					return {
						gamebox: gamebox._id,
						name: gamebox.name,
						sn: gamebox.serialNumber,
						gameName: gamebox.games[0].name,
						gameId: gamebox.games[0]._id,
						game: gamebox.games[0]._id,
						readingIn: 0,
						readingOut: 0,
						branch: this.selectedBranch,
						readingType: this.selectedOperation,
						transactionDate: this.transactionDate,
						locationOrder: gamebox.locationOrder || null,
					};
				});

				// sort by locationOrder or by serial number
				this.gameboxTransactions.sort((a, b) => {
					if (a.locationOrder) {
						if (a.locationOrder < b.locationOrder) return -1;
					}
					if (b.locationOrder) {
						if (b.locationOrder < a.locationOrder) return 1;
					}
					if (a.sn < b.sn) return -1;
					if (b.sn < a.sn) return 1;
				});
				// Get iot transactions
				let { data } = await this.getLatestIotTransactions(this.selectedBranch);
				data.results.map((d) => {
					this.gameboxTransactions.forEach((t) => {
						if (d.gamebox._id === t.gamebox) {
							t.readingIn = d.readingIn;
							t.readingOut = d.readingOut;
						}
					});
				});
			},

			async submitMachineForm() {
				//Loop over the grambox transactions and send request to api for each
				for (let i = 0; i < this.gameboxTransactions.length; i++) {
					let transaction = { ...this.gameboxTransactions[i] };
					transaction.isJackpot = this.isJackpot;
					transaction.readingIn = Number(transaction.readingIn);
					transaction.readingOut = Number(transaction.readingOut);
					const result = await this.createTransaction(transaction);
					//Display error message if api returns error
					if (result.status != 201) {
						this.$bvToast.toast(
							`Machine ${transaction.name} information could not be uploaded. ${result.message}`,
							{
								title: `Upload Unsuccessful`,
								variant: "danger",
								solid: true,
							}
						);
					} else {
						//Display success message
						this.$bvToast.toast(
							`Machine ${transaction.name} information was successfully uploaded.`,
							{
								title: `Uploaded Successfully`,
								variant: "success",
								solid: true,
							}
						);
					}
				}
				// Mark IOT transactions as completed
				await this.markIotComplete({
					user: this.currentUser._id,
					branch: this.selectedBranch,
					transactionDate: this.transactionDate,
				});
				this.getTransactionDetails();
			},
			/**
			 * Get the transaction details for the selected branch
			 */
			async getTransactionDetails() {
				const result = await this.getTransactionSummaryDetails({
					branchId: this.selectedBranch,
					startDate: this.transactionDate,
				});

				this.transactionSummary = result.data.find(
					(t) => t._id.readingDate == this.transactionDate
				);
			},

			handleTabChange(prevIndex, nextIndex) {
				if (nextIndex == 0) {
					this.wizardInstructions =
						"This form wizard will assist you with entering machine values for your branch. Please select a branch then click the next button";
				} else if (nextIndex == 1) {
					this.wizardInstructions =
						"Let us know if the jackpot for this branch has been claimed or not.";
				} else if (nextIndex == 2) {
					this.loadGameboxes();
					this.wizardInstructions =
						"Please Enter the values for reading in and reading out as they are show on their respective machines";
				} else {
					this.wizardInstructions = "";
				}
			},

			goBack() {
				this.$refs["wizard"].reset();
			},

			formatAsMoney(value) {
				return formatter.format(value);
			},

			findPercentage(partialValue, totalValue) {
				return ((100 * partialValue) / totalValue).toFixed(2);
			},
		},
	};
</script>
